import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import H2 from "../components/h2"
import Emphasis from "../components/emphasis"
import { Link } from "gatsby"
import Button from "../components/button"
import { StaticImage } from "gatsby-plugin-image"

export default function AboutPage() {
  return (
    <Layout>
      <Seo title="About" />
      <div className="py-8 px-8 lg:px-16">
        <H2>About Us</H2>
        <div className="flex flex-col lg:flex-row">
          <section className="basis-2/3">
            <p className="pt-8">
              Canopy Insulation, Co. is a full-service residential and
              commercial spray foam insulation and polyurea coating contractor
              based in Clarion, PA. Our service area includes Pennsylvania, West
              Virginia, Ohio, and New York.
            </p>
            <p className="pt-4">
              Canopy is a veteran owned and operated business, named as an ode
              to the parachutes used by founder and operator Tom Smith during
              his time as Airborne Ranger in the 75th Ranger Regiment.
            </p>
            <p className="pt-4">
              With Canopy you can expect our team to uphold our core values of
              high quality and professional service in every job we do. We pride
              ourselves in protecting and preserving your investments while
              ensuring 100% satisfaction with the service you receive.
            </p>
            <p className="pt-4">
              Whether you are a homeowner looking to save on your energy bill or
              a contractor looking for spray foam insulation or waterproofing
              services, Canopy has you covered.
            </p>
            <div className="text-center py-8">
              <Emphasis className="py-4 block">
                Ready to take the next step?
              </Emphasis>
              {/* <Link to="/contact" className="py-4 block">
                <Button className="text-3xl">Get a free quote</Button>
              </Link> */}
              <a
                href="https://canopyinsulationco.dripjobs.com/appointmentrequests"
                className="py-4 block"
              >
                <Button className="text-3xl">Get a free quote</Button>
              </a>
            </div>
          </section>
          <section className="grow flex flex-col items-center justify-center">
            <div className="text-center font-bold">Service Area</div>
            <figure className="py-8">
              <StaticImage
                src="../images/service-area.png"
                alt="Map of United States with highlighted service area of Pennsylvania, New York, Ohio, and West Virginia"
                objectFit="cover"
                objectPosition="right"
                placeholder="blurred"
                className="w-96 rounded-xl"
              />
            </figure>
            <figure className="py-8">
              <StaticImage
                src="../images/tom-smith.jpeg"
                alt="Canopy Insulation, Co founder Tom Smith admiring a completed spray foam insulation job"
                objectFit="cover"
                objectPosition="right"
                placeholder="blurred"
                className="w-80 rounded-xl"
              />
            </figure>
          </section>
        </div>
      </div>
    </Layout>
  )
}
