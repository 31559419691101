import * as React from "react"

const Emphasis = ({ className, children }) => (
  <strong
    className={`text-2xl lg:text-3xl font-light font-heading ${className}`}
  >
    {children}
  </strong>
)

export default Emphasis
